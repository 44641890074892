// ChangeOwnerPassword.jsx
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import axios from "axios";
import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "./dialogstyle";
import Iconify from "../components/iconify";

const localhost = process.env.REACT_APP_API_URL;

const ChangeOwnerPassword = ({ open, setOpen }) => {
  const [loader, setLoader] = useState(false);

  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    con: false,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const [formData, setFormData] = useState({
    // oldpassword: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    oldPassword: false,
    password: false,
    confirmPassword: false,
  });

  const handleSave = () => {
    // Validate required fields "oldpassword",
    const requiredFields = ["password", "oldPassword", "confirmPassword"];
    let isValid = true;
    const newError = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });

    // Validate password and confirm password match
    if (formData.password !== formData.confirmPassword) {
      newError.password = true;
      newError.confirmPassword = "password must match";
      isValid = false;
    }
    if (formData.password.length < 6) {
      newError.password = "password must minimus 6 digits!";
      isValid = false;
    }

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }

    const id = localStorage.getItem("shopOwnerId");
    if (id) {
      setLoader(true);
      axios
        .post(`${localhost}/shop-owners/changeOwnPassword/${id}`, {
          oldPassword: formData.oldPassword,
          newPassword: formData.password,
        })
        .then((res) => {
          console.log(res);
          setOpen(false);
          toast.success("Changed password successfuly!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            err.response?.data?.error || "Error editing password...!"
          );
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Change Passowrd</CustomDialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          label="Old Password"
          type={showPassword.old ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={formData.oldPassword}
          error={error.oldPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setShowPassword({ ...showPassword, old: !showPassword.old })
                  }
                  edge="end"
                >
                  <Iconify
                    icon={
                      showPassword.old ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange("oldPassword")}
        />
        <TextField
          margin="normal"
          label="New Password"
          type={showPassword.new ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={formData.password}
          error={error.password}
          helperText={error.password && error.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setShowPassword({ ...showPassword, new: !showPassword.new })
                  }
                  edge="end"
                >
                  <Iconify
                    icon={
                      showPassword.new ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange("password")}
        />
        <TextField
          margin="normal"
          label="Confirm Password"
          type={showPassword.con ? "text" : "password"}
          fullWidth
          variant="outlined"
          value={formData.confirmPassword}
          error={error.confirmPassword}
          helperText={error.confirmPassword && error.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setShowPassword({ ...showPassword, con: !showPassword.con })
                  }
                  edge="end"
                >
                  <Iconify
                    icon={
                      showPassword.con ? "eva:eye-fill" : "eva:eye-off-fill"
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleChange("confirmPassword")}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {loader ? (
          <div style={{ padding: "0 15px" }}>
            <CircularProgress />
          </div>
        ) : (
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default ChangeOwnerPassword;
